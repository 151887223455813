// src/components/ResetPassword.js
import React, { useState } from 'react';
import { auth } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Correo de restablecimiento de contraseña enviado con éxito.');
    } catch (error) {
      setMessage('Error al enviar el correo de restablecimiento de contraseña: ' + error.message);
    }
  };

  return (
    <div>
      <h2>Restablecer Contraseña</h2>
      <input
        type="email"
        placeholder="Correo Electrónico"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={handleResetPassword}>Enviar Correo de Restablecimiento</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ResetPassword;
