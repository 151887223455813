// src/components/ForgotPassword.js
import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import styles from './ForgotPassword.module.css'; // Import the module CSS

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('¡Correo de restablecimiento de contraseña enviado! Por favor, revisa tu bandeja de entrada.');
      setTimeout(() => {
        navigate('/customer/login');
      }, 5000); // Redirect to login after 5 seconds
    } catch (error) {
      setMessage('Ocurrió un error al intentar restablecer la contraseña. Por favor, intenta de nuevo.');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.formWrapper}>
        <h2>Restablecer Contraseña</h2>
        {message && <p className={styles.message}>{message}</p>}
        <form onSubmit={handleResetPassword}>
          <div className={styles.formGroup}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Ingresa tu correo electrónico"
              required
            />
          </div>
          <button type="submit" className={styles.btnPrimary}>Restablecer Contraseña</button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
