import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { loadOrderHistory } from '../services/firestoreService';
import { Link } from 'react-router-dom';
import styles from './OrderHistory.module.css'; // Importar el módulo CSS

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const unsubscribe = loadOrderHistory((ordersData) => {
      // Ordenar las órdenes por fecha, de la más reciente a la más antigua
      const sortedOrders = ordersData.sort((a, b) => new Date(b.date) - new Date(a.date));
      setOrders(sortedOrders);
    });

    return () => unsubscribe();
  }, []);

  // Función auxiliar para formatear la moneda en colones costarricenses (₡)
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CR', {
      style: 'currency',
      currency: 'CRC',
      minimumFractionDigits: 0,
    }).format(value);
  };

  return (
    <div className={styles.orderHistoryContainer}>
      <h2 className={styles.orderHistoryTitle}>Historial de Pedidos</h2>
      {orders.length > 0 ? (
        <ul className={styles.orderListGroup}>
          {orders.map((order) => (
            <li key={order.id} className={styles.orderListItem}>
              <h5 className={styles.orderNumber}>
                Orden #{order.orderNumber || order.id}
              </h5>
              <p className={styles.orderText}>Fecha: {new Date(order.date).toLocaleDateString()}</p>
              <p className={styles.orderText}>Estado: {order.status}</p>
              <p className={styles.orderText}>Estado de Pago: {order.paymentStatus || 'Pago Pendiente'}</p>
              <ul className={styles.orderItemList}>
                {order.items.map((item, itemIndex) => (
                  <li key={itemIndex} className={styles.orderItem}>
                    {item.name} - {formatCurrency(item.price)} ({item.quantity} unidades)
                  </li>
                ))}
              </ul>
              <p className={styles.orderTotal}>Total: {formatCurrency(order.total)}</p>
            </li>
          ))}
        </ul>
      ) : (
        <div>
          <p>No se encontraron pedidos previos.</p>
          <p><Link to="/products" className={styles.orderLink}>Explora nuestros productos</Link> y realiza tu primer pedido.</p>
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
