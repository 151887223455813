import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, getDocs } from 'firebase/firestore';
import { Table, Modal, Button } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import styles from './AdminUsers.module.css';
import { getFunctions, httpsCallable } from "firebase/functions"; // Import Firebase Functions

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const firestore = getFirestore();

  const handleSendPromotionalEmail = async () => {
    if (!users.length) {
      alert("No hay usuarios registrados para enviar correos.");
      return;
    }

    setLoading(true);
    try {
      const functions = getFunctions(); // Initialize Functions
      const sendEmail = httpsCallable(functions, "sendPromotionalEmail"); // Call the backend function

      const emails = users.map((user) => user.email).filter(Boolean); // Extract all email addresses
      const result = await sendEmail({ emails }); // Send the email data to the backend

      alert(`Promotional emails sent to ${result.data.count} users!`);
    } catch (error) {
      console.error("Error sending promotional emails:", error);
      alert("Hubo un problema al enviar los correos.");
    } finally {
      setLoading(false);
    }
  };
  


  useEffect(() => {
    const fetchUsers = async () => {
        setLoading(true);
        const q = query(collection(firestore, 'users'));
        const querySnapshot = await getDocs(q);
        const usersData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            dateRegistered: data.dateRegistered ? new Date(data.dateRegistered) : null, // Ensure valid date
            shippingInfo: {
                provincia: data.provincia || 'N/A',
                canton: data.canton || 'N/A',
                distrito: data.distrito || 'N/A',
                direccion: data.direccion || 'N/A',
                codigoPostal: data.codigoPostal || 'N/A',
              },
          };
        });
        setUsers(usersData);
        setLoading(false);
      };
      
      

    fetchUsers();
  }, [firestore]);

  

  const handleDownloadReport = () => {
    const formattedUsers = users.map((user) => ({
      'Fecha Registrado': user.dateRegistered
        ? new Date(user.dateRegistered).toLocaleDateString('es-CR')
        : 'N/A',
      'Nombre': user.name || 'N/A',
      'Apellido': user.lastName || 'N/A',
      'Correo Electrónico': user.email || 'N/A',
      'Teléfono': user.telefono || 'N/A',
      'Fecha de Nacimiento': user.dob
        ? new Date(user.dob).toLocaleDateString('es-CR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
        : 'N/A',
      'Provincia': user.shippingInfo?.provincia || 'N/A',
      'Número de Pedidos': user.ordersPlaced || 0,
      'Monto Total': user.totalSpent
        ? new Intl.NumberFormat('es-CR', {
            style: 'currency',
            currency: 'CRC',
            minimumFractionDigits: 0,
          }).format(user.totalSpent)
        : 'N/A',
    }));
  
    // Create worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(formattedUsers);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Usuarios');
  
    // Write to file
    XLSX.writeFile(workbook, 'Reporte_Usuarios.xlsx');
  };
  
  
  const handleOpenUserDetailsModal = (user) => {
    setSelectedUser(user);
    setShowUserDetailsModal(true);
  };

  const handleCloseUserDetailsModal = () => {
    setShowUserDetailsModal(false);
    setSelectedUser(null);
  };

  const formatCurrency = (value) =>
    new Intl.NumberFormat('es-CR', {
      style: 'currency',
      currency: 'CRC',
      minimumFractionDigits: 0,
    }).format(value || 0);

  return (
    <div className={styles.adminUsersContainer}>
      {loading && <div className={styles.loadingOverlay}></div>}

      <h2 className={styles.adminUsersTitle}>Administrar Usuarios</h2>

      <Button
          onClick={handleSendPromotionalEmail}
          className={styles.adminUsersButton}
        >
          Enviar Promoción
        </Button>
        
      <Button onClick={handleDownloadReport} className={styles.adminUsersButton}>
        Descargar Reporte
      </Button>

      <Table striped bordered hover className={styles.adminUsersTable}>
        <thead>
          <tr>
            <th>Fecha Registrado</th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Correo Electrónico</th>
            <th>Teléfono</th>
            <th>Fecha de Nacimiento</th>
            <th>Provincia</th>
            <th>Número de Pedidos</th>
            <th>Monto Total</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>
  {user.dateRegistered
    ? new Date(user.dateRegistered).toLocaleDateString('es-CR')
    : 'N/A'}
</td>

              <td>{user.name}</td>
              <td>{user.lastName}</td>
              <td>{user.email}</td>
              <td>{user.telefono || 'N/A'}</td>
              <td>{user.dob
                ? new Date(user.dob).toLocaleDateString('es-CR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    })
                : 'N/A'
                }</td>
              <td>{user.shippingInfo?.provincia || 'N/A'}</td>
              <td>{user.ordersPlaced || 0}</td>
              <td>{formatCurrency(user.totalSpent)}</td>
              <td>
                <Button
                  onClick={() => handleOpenUserDetailsModal(user)}
                  className={styles.adminUsersButton}
                >
                  Ver Detalles
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal
        show={showUserDetailsModal}
        onHide={handleCloseUserDetailsModal}
        size="lg"
        centered
        className={styles.modalContainer}
      >
        <Modal.Header closeButton className={styles.modalHeader}>
          <Modal.Title>Detalles del Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
  {selectedUser && (
    <div>
      <h4>Información Personal</h4>
      <p>
        <strong>Nombre:</strong> {selectedUser.name}
      </p>
      <p>
        <strong>Apellido:</strong> {selectedUser.lastName}
      </p>
      <p>
        <strong>Correo Electrónico:</strong> {selectedUser.email}
      </p>
      <p>
        <strong>Teléfono:</strong> {selectedUser.telefono || 'N/A'}
      </p>
      <p>
        <strong>Fecha Registrado:</strong> 
        {selectedUser.dateRegistered
          ? new Date(selectedUser.dateRegistered).toLocaleDateString('es-CR')
          : 'N/A'}
      </p>
      <p>
        <strong>Fecha de Nacimiento:</strong>{' '}
        {selectedUser.dob
          ? new Date(selectedUser.dob).toLocaleDateString('es-CR', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          : 'N/A'}
      </p>

      <h4>Información de Envío</h4>
      <p>
        <strong>Provincia:</strong> {selectedUser.shippingInfo?.provincia || 'N/A'}
      </p>
      <p>
        <strong>Cantón:</strong> {selectedUser.shippingInfo?.canton || 'N/A'}
      </p>
      <p>
        <strong>Distrito:</strong> {selectedUser.shippingInfo?.distrito || 'N/A'}
      </p>
      <p>
        <strong>Dirección:</strong> {selectedUser.shippingInfo?.direccion || 'N/A'}
      </p>
      <p>
        <strong>Código Postal:</strong> {selectedUser.shippingInfo?.codigoPostal || 'N/A'}
      </p>

      <h4>Historial de Pedidos</h4>
      <p>
        <strong>Número de Pedidos:</strong> {selectedUser.ordersPlaced || 0}
      </p>
      <p>
        <strong>Total Gastado:</strong> {formatCurrency(selectedUser.totalSpent)}
      </p>
    </div>
  )}
</Modal.Body>

        <Modal.Footer className={styles.modalFooter}>
          <Button onClick={handleCloseUserDetailsModal} className={styles.modalCloseButton}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminUsers;
