// src/components/AdminManagement.js
import React, { useState, useEffect } from 'react';
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  getDoc,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Swal from 'sweetalert2'; // Import SweetAlert2
import withReactContent from 'sweetalert2-react-content'; // For SweetAlert customization
import styles from './AdminManagement.module.css'; // Import CSS module

const MySwal = withReactContent(Swal); // Customize SweetAlert

const AdminManagement = () => {
  const [adminEmail, setAdminEmail] = useState('');
  const [admins, setAdmins] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [businessSettings, setBusinessSettings] = useState({
    isManual: false,
    isOpen: false,
    open: '09:00',
    close: '19:00',
  });
  const [openTime, setOpenTime] = useState('09:00');
  const [closeTime, setCloseTime] = useState('19:00');

  const firestore = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    const fetchAdmins = async () => {
      setLoading(true); // Start loading
      const q = collection(firestore, 'admins');
      const querySnapshot = await getDocs(q);
      const adminsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAdmins(adminsList);
      setLoading(false); // End loading
    };

    const fetchBusinessSettings = async () => {
      const docRef = doc(firestore, 'businessSettings', 'main');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const settings = docSnap.data();
        setBusinessSettings(settings);
        setOpenTime(settings.open || '09:00');
        setCloseTime(settings.close || '19:00');
        autoUpdateStoreStatus(settings); // Automatically update store status if needed
      } else {
        console.error('Business settings not found.');
      }
    };

    const checkAdmin = async (user) => {
      if (user) {
        const q = collection(firestore, 'admins');
        const querySnapshot = await getDocs(q);
        const adminsList = querySnapshot.docs.map((doc) => doc.data().email);
        setIsAdmin(adminsList.includes(user.email));
      }
    };

    fetchAdmins();
    fetchBusinessSettings();

    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      checkAdmin(user);
    });
  }, [firestore, auth]);

  const autoUpdateStoreStatus = (settings) => {
    if (!settings.isManual) {
      const currentTime = new Date();
      const currentHours = currentTime.getHours();
      const currentMinutes = currentTime.getMinutes();
  
      const [openHours, openMinutes] = settings.open.split(':').map(Number);
      const [closeHours, closeMinutes] = settings.close.split(':').map(Number);
  
      const currentTotalMinutes = currentHours * 60 + currentMinutes;
      const openTotalMinutes = openHours * 60 + openMinutes;
      const closeTotalMinutes = closeHours * 60 + closeMinutes;
  
      const isOpen =
        currentTotalMinutes >= openTotalMinutes &&
        currentTotalMinutes <= closeTotalMinutes;
  
      // Only update if the status has changed
      if (settings.isOpen !== isOpen) {
        handleUpdateBusinessSettings({ isOpen });
      }
    }
  };
  

  const handleAddAdmin = async () => {
    // Email validation regex
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!adminEmail || !emailPattern.test(adminEmail)) {
      MySwal.fire('Error', 'Por favor, ingresa una dirección de correo válida.', 'warning');
      return;
    }

    setLoading(true); // Start loading spinner
    try {
      await addDoc(collection(firestore, 'admins'), { email: adminEmail });
      setAdminEmail('');
      MySwal.fire('Éxito', '¡Admin agregado exitosamente!', 'success');

      // Refresh the admin list
      const q = collection(firestore, 'admins');
      const querySnapshot = await getDocs(q);
      const adminsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAdmins(adminsList);
    } catch (error) {
      MySwal.fire('Error', 'Hubo un problema al agregar el admin.', 'error');
    } finally {
      setLoading(false); // End loading spinner
    }
  };

  const handleDeleteAdmin = async (id) => {
    MySwal.fire({
      title: '¿Estás seguro?',
      text: 'Esto eliminará al admin de la lista.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, ¡elimínalo!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true); // Start loading spinner
        try {
          await deleteDoc(doc(firestore, 'admins', id));
          MySwal.fire('Eliminado', '¡Admin eliminado exitosamente!', 'success');

          // Refresh the admin list
          const q = collection(firestore, 'admins');
          const querySnapshot = await getDocs(q);
          const adminsList = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setAdmins(adminsList);
        } catch (error) {
          MySwal.fire('Error', 'Hubo un problema al eliminar el admin.', 'error');
        } finally {
          setLoading(false); // End loading spinner
        }
      }
    });
  };

  const handleUpdateBusinessSettings = async (updatedSettings) => {
    setLoading(true); // Start loading spinner
    try {
      const docRef = doc(firestore, 'businessSettings', 'main');
      await updateDoc(docRef, updatedSettings);
      setBusinessSettings((prev) => ({ ...prev, ...updatedSettings }));
      MySwal.fire('Éxito', '¡Configuración actualizada exitosamente!', 'success');
    } catch (error) {
      MySwal.fire('Error', 'Hubo un problema al actualizar la configuración.', 'error');
    } finally {
      setLoading(false); // End loading spinner
    }
  };

  const toggleIsManual = () => {
    handleUpdateBusinessSettings({ isManual: !businessSettings.isManual });
    if (!businessSettings.isManual) autoUpdateStoreStatus(businessSettings); // Check status when switching to automatic
  };

  const toggleIsOpen = () => {
    handleUpdateBusinessSettings({ isOpen: !businessSettings.isOpen });
  };

  const updateBusinessHours = () => {
    handleUpdateBusinessSettings({ open: openTime, close: closeTime });
    autoUpdateStoreStatus({ ...businessSettings, open: openTime, close: closeTime });
  };

  if (!isAdmin) {
    return <p>No tienes acceso a esta página.</p>;
  }

  return (
    <div className={styles.adminManagementContainer}>
      {loading && <div className={styles.loadingOverlay}></div>}
      <h2 className={styles.adminManagementTitle}>Admin: Manejo de Cuentas</h2>
      {currentUser && <p className={styles.loggedInUser}>Accediste como: {currentUser.email}</p>}

      <div className={styles.inputGroup}>
        <input
          type="email"
          value={adminEmail}
          onChange={(e) => setAdminEmail(e.target.value)}
          placeholder="Ingresa admin email"
          className={styles.inputField}
        />
        <button className={styles.addButton} onClick={handleAddAdmin}>
          Agregar Admin
        </button>
      </div>

      <h3>Admins Actuales:</h3>
      <ul className={styles.adminList}>
        {admins.map((admin) => (
          <li key={admin.id} className={styles.adminItem}>
            <span className={styles.adminEmail}>{admin.email}</span>
            <button className={styles.removeButton} onClick={() => handleDeleteAdmin(admin.id)}>
              Eliminar
            </button>
          </li>
        ))}
      </ul>

      <h3>Configuración de Horarios:</h3>
      <div className={styles.businessSettings}>
        <label className={styles.switchLabel}>
          <input
            type="checkbox"
            checked={businessSettings.isManual}
            onChange={toggleIsManual}
            className={styles.switchInput}
          />
          <span className={styles.switchSlider}></span>
          Modo Manual
        </label>
        {businessSettings.isManual && (
          <label className={styles.switchLabel}>
            <input
              type="checkbox"
              checked={businessSettings.isOpen}
              onChange={toggleIsOpen}
              className={styles.switchInput}
            />
            <span className={styles.switchSlider}></span>
            ¿Abierto?
          </label>
        )}
        {!businessSettings.isManual && (
          <div className={styles.timeSettings}>
            <label>
              Hora de Apertura:
              <input
                type="time"
                value={openTime}
                onChange={(e) => setOpenTime(e.target.value)}
                className={styles.timeInput}
              />
            </label>
            <label>
              Hora de Cierre:
              <input
                type="time"
                value={closeTime}
                onChange={(e) => setCloseTime(e.target.value)}
                className={styles.timeInput}
              />
            </label>
            <button onClick={updateBusinessHours} className={styles.updateButton}>
              Actualizar Horarios
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminManagement;
