import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collection, addDoc, updateDoc, deleteDoc, doc, getDocs, query, where, writeBatch } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { addCategory, getCategories, deleteCategory } from '../services/firestoreService';
import Swal from 'sweetalert2'; // SweetAlert2 for alerts
import styles from './AdminProducts.module.css'; // Import scoped CSS
import Modal from 'react-modal';

const AdminProducts = () => {
  const [products, setProducts] = useState([]);
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productStock, setProductStock] = useState(''); // General stock for all options
  const [productCategory, setProductCategory] = useState('');
  const [productShortDescription, setProductShortDescription] = useState(''); // Short Description
  const [productFullDescription, setProductFullDescription] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [productImages, setProductImages] = useState([]); // Updated to handle multiple images
  const [principalImageIndex, setPrincipalImageIndex] = useState(0); // Track the principal image index
  const [categories, setCategories] = useState([]);
  const [productOptions, setProductOptions] = useState([{ name: '', price: '' }]); // Removed stock from options
  const [showCategories, setShowCategories] = useState(false);
  const [editProduct, setEditProduct] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addProductModalOpen, setAddProductModalOpen] = useState(false); // Modal state for adding new products
  const [loading, setLoading] = useState(false); // Loading state
  const firestore = getFirestore();
  const storage = getStorage();
  const fileInputRef = useRef(null);
  const [hasTarjeta, setHasTarjeta] = useState(false);

  useEffect(() => {
    const fetchProductsAndCategories = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'products'));
      const productsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productsList);

      const categoriesSnapshot = await getCategories();
      const categoriesList = categoriesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCategories(categoriesList);
    };

    fetchProductsAndCategories();
  }, [firestore]);

  const handleAddProduct = async () => {
    if (!productName || !productPrice || !productStock || !productCategory || productImages.length === 0 || !productShortDescription || !productFullDescription) {
      Swal.fire('Error', 'Please fill in all required fields.', 'error');
      return;
    }

    if (productShortDescription.length > 43) {
      Swal.fire('Error', 'Short description cannot exceed 43 characters.', 'error');
      return;
    }

    setLoading(true); // Show loading indicator
    try {
      let category = productCategory;

      if (category === 'Agregar Nueva Categoría' && newCategory) {
        const newCategoryDoc = await addCategory(newCategory);
        category = newCategory;
        setCategories([...categories, { id: newCategoryDoc.id, name: newCategory }]);
        setNewCategory('');
      }

      // Upload all images
      const imageUrls = [];
      for (const image of productImages) {
        const storageRef = ref(storage, `products/${image.name}`);
        await uploadBytes(storageRef, image);
        const imageUrl = await getDownloadURL(storageRef);
        imageUrls.push(imageUrl);
      }

      await addDoc(collection(firestore, 'products'), {
        name: productName,
        price: parseFloat(productPrice),
        stock: parseInt(productStock, 10),
        category: category,
        shortDescription: productShortDescription, // Add short description
        fullDescription: productFullDescription, // Add Descripción Completa
        imageUrls: imageUrls,
        principalImageUrl: imageUrls[principalImageIndex],
        options: productOptions.filter(option => option.name && option.price),
        hasTarjeta: hasTarjeta,
        orderCount: 0,
        createdAt: new Date(),
        disabled: false
      });

      Swal.fire('Éxito', '¡Producto agregado exitosamente!', 'success');

      // Reset state
      setProductName('');
      setProductPrice('');
      setProductStock('');
      setProductCategory('');
      setProductShortDescription(''); // Reset short description
      setProductFullDescription(''); // Reset Descripción Completa
      setProductImages([]);
      setPrincipalImageIndex(0);
      setProductOptions([{ name: '', price: '' }]);
      fileInputRef.current.value = null;

      // Refresh products list
      const querySnapshot = await getDocs(collection(firestore, 'products'));
      const productsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productsList);

      setAddProductModalOpen(false); // Close the modal after adding the product
    } catch (error) {
      Swal.fire('Error', 'Something went wrong. Please try again.', 'error');
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };
            
  const handleAddOption = () => {
    setProductOptions([...productOptions, { name: '', price: '' }]);
  };

  const handleOptionChange = (index, field, value) => {
    const updatedOptions = productOptions.map((option, i) =>
      i === index ? { ...option, [field]: value } : option
    );
    setProductOptions(updatedOptions);
  };

  const handleDeleteImage = async (imageUrl, e) => {
    e.preventDefault(); // Prevent page reload
    
    setLoading(true);
    try {
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);
      const updatedImages = editProduct.imageUrls.filter(url => url !== imageUrl);
    
      // If the deleted image is the principal image
      if (imageUrl === editProduct.principalImageUrl) {
        if (updatedImages.length > 0) {
          // Set the first available image as the new principal if there are still images left
          setPrincipalImageIndex(0);
        } else {
          // If no images are left, set principalImageIndex to null
          setPrincipalImageIndex(null);
        }
      }
      
      // Update the editProduct state with the updated images
      setEditProduct({ ...editProduct, imageUrls: updatedImages });
      Swal.fire('Success', 'Image deleted successfully.', 'success');
    } catch (error) {
      Swal.fire('Error', 'Error deleting image.', 'error');
    } finally {
      setLoading(false);
    }
  };
  
  

  const openModal = (product) => {
    setEditProduct(product);
    setProductName(product.name);
    setProductPrice(product.price);
    setProductStock(product.stock);
    setProductCategory(product.category);
    setProductShortDescription(product.shortDescription); // Load short description
    setProductFullDescription(product.fullDescription); // Load Descripción Completa
    setProductImages([]); // Reset product images to empty for updates
    setProductOptions(product.options || [{ name: '', price: '' }]);
    setPrincipalImageIndex(product.imageUrls ? product.imageUrls.indexOf(product.principalImageUrl) : 0);
    setHasTarjeta(product.hasTarjeta || false); // Set hasTarjeta based on the product value
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setEditProduct(null);
    setProductName('');
    setProductPrice('');
    setProductStock('');
    setProductCategory('');
    setProductShortDescription('');
    setProductFullDescription('');
    setProductImages([]);
    setPrincipalImageIndex(0);
    setProductOptions([{ name: '', price: '' }]);
    fileInputRef.current.value = null;
    setModalIsOpen(false);
  };
  const handleUpdateProduct = async () => {
    if (!productName || !productPrice || !productStock || !productCategory || !productShortDescription || !productFullDescription) {
      Swal.fire('Error', 'Please fill in all required fields, including descriptions.', 'error');
      return;
    }
  
    // Ensure short description is within 43 characters
    if (productShortDescription.length > 43) {
      Swal.fire('Error', 'Short Description must be 43 characters or less.', 'error');
      return;
    }
  
    setLoading(true); // Show loading indicator
    try {
      if (editProduct) {
        let category = productCategory;
  
        if (category === 'Agregar Nueva Categoría' && newCategory) {
          const newCategoryDoc = await addCategory(newCategory);
          category = newCategory;
          setCategories([...categories, { id: newCategoryDoc.id, name: newCategory }]);
          setNewCategory('');
        }
  
        const productRef = doc(firestore, 'products', editProduct.id);
        let imageUrls = editProduct.imageUrls || [];
  
        // Upload new images if provided
        if (productImages.length > 0) {
          for (const image of productImages) {
            const storageRef = ref(storage, `products/${image.name}`);
            await uploadBytes(storageRef, image);
            const imageUrl = await getDownloadURL(storageRef);
            imageUrls.push(imageUrl);
          }
        }
  
        await updateDoc(productRef, {
          name: productName,
          price: parseFloat(productPrice),
          stock: parseInt(productStock, 10),
          category: category,
          shortDescription: productShortDescription, // Update short description
          fullDescription: productFullDescription, // Update Descripción Completa
          imageUrls: imageUrls,
          principalImageUrl: imageUrls[principalImageIndex],
          options: productOptions,
          hasTarjeta: hasTarjeta
        });
  
        Swal.fire('Success', 'Product updated successfully!', 'success');
  
        // Refresh products list
        const querySnapshot = await getDocs(collection(firestore, 'products'));
        const productsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setProducts(productsList);
  
        closeModal();
      }
    } catch (error) {
      Swal.fire('Error', 'Something went wrong. Please try again.', 'error');
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };
  


  const handleDeleteProduct = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to undo this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          await deleteDoc(doc(firestore, 'products', id));
          Swal.fire('Deleted!', 'Product deleted successfully.', 'success');

          // Refresh products list
          const querySnapshot = await getDocs(collection(firestore, 'products'));
          const productsList = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setProducts(productsList);
        } catch (error) {
          Swal.fire('Error', 'Error deleting the product. Please try again.', 'error');
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleDisableProduct = async (id, disabled) => {
    setLoading(true);
    try {
      await updateDoc(doc(firestore, 'products', id), { disabled });
      Swal.fire('Success', `Product ${disabled ? 'disabled' : 'enabled'} successfully.`, 'success');

      // Refresh products list
      const querySnapshot = await getDocs(collection(firestore, 'products'));
      const productsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productsList);
    } catch (error) {
      Swal.fire('Error', 'Error updating the product. Please try again.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDisableCategory = async (id, disabled) => {
    setLoading(true);
    try {
      const categoryRef = doc(firestore, 'categories', id);
      await updateDoc(categoryRef, { disabled });

      const productsQuery = query(collection(firestore, 'products'), where('category', '==', categories.find(c => c.id === id).name));
      const productsSnapshot = await getDocs(productsQuery);
      const batch = writeBatch(firestore);

      productsSnapshot.forEach((productDoc) => {
        const productRef = doc(firestore, 'products', productDoc.id);
        batch.update(productRef, { disabled });
      });

      await batch.commit();
      Swal.fire('Success', `Category and its products ${disabled ? 'disabled' : 'enabled'} successfully.`, 'success');

      // Refresh categories and products lists
      const categoriesSnapshot = await getCategories();
      setCategories(categoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      const querySnapshot = await getDocs(collection(firestore, 'products'));
      setProducts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      Swal.fire('Error', 'Error disabling/enabling the category. Please try again.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCategory = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to undo this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          await deleteCategory(id);
          setCategories(categories.filter(category => category.id !== id));
          Swal.fire('Deleted!', 'Category deleted successfully.', 'success');
        } catch (error) {
          Swal.fire('Error', 'Error deleting the category. Please try again.', 'error');
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleImageUpload = (e) => {
    setProductImages(Array.from(e.target.files)); // Allow multiple image uploads
  };

  const handlePrincipalImageSelect = (index, e) => {
    e.preventDefault(); // Prevent default page reload behavior
    setPrincipalImageIndex(index); // Set the selected image as principal
  };
  
  return (
    <div className={styles.adminProductsContainer}>
      {loading && <div className={styles.loadingOverlay}></div>} {/* Loading Overlay */}
  
      <h2 className={styles.popupTitle}>Admin: Manejo de Productos</h2>
  
      {/* Centered Buttons */}
      <div className={styles.centeredButtonRow}>
        <button className={styles.popupButton} onClick={() => setAddProductModalOpen(true)}>
          Agregar Producto
        </button>
        <button className={styles.popupButton} onClick={() => setShowCategories(!showCategories)}>
          {showCategories ? 'Ocultar Categorías' : 'Mostrar Categorías'}
        </button>
      </div>
  
      {showCategories && (
        <div className={styles.categoriesSection}>
          <h3 className={styles.sectionTitle}>Manejo de Categorías</h3>
          <div className={styles.adminTableContainer}>
            <table className={styles.adminTable}>
              <thead>
                <tr>
                  <th>Nombre de  Categoría</th>
                  <th>Status</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {categories.map((category) => (
                  <tr key={category.id}>
                    <td>{category.name}</td>
                    <td>{category.disabled ? 'Desactivada' : 'Activa'}</td>
                    <td>
                      <button
                        className={`${styles.tableButton} ${styles.activeButton}`}
                        onClick={() => handleDisableCategory(category.id, !category.disabled)}
                      >
                        {category.disabled ? 'Activar' : 'Desactivar'}
                      </button>
                      <button
                        className={`${styles.tableButton} ${styles.deleteButton}`}
                        onClick={() => handleDeleteCategory(category.id)}
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
  
      <div className={styles.centeredSection}>
        <h3 className={styles.sectionTitle}>Productos Actuales</h3>
        <ul className={styles.productList}>
          {products.map((product) => (
            <li key={product.id} className={styles.productItem}>
              <div className={styles.productInfo}>
                <img src={product.principalImageUrl} alt={product.name} className={styles.productImage} />
                {product.name} - ${product.price} - Stock: {product.stock} -{' '}
                {product.disabled ? 'Desactivado' : 'Activado'}
              </div>
              <div className={styles.buttonGroup}>
                <button className={styles.popupButton} onClick={() => openModal(product)}>
                  Editar
                </button>
                <button
                  className={`${styles.popupButton} ${styles.activeButton}`}
                  onClick={() => handleDisableProduct(product.id, !product.disabled)}
                >
                  {product.disabled ? 'Activar' : 'Desactivar'}
                </button>
                <button className={`${styles.popupButton} ${styles.deleteButton}`} onClick={() => handleDeleteProduct(product.id)}>
                  Eliminar
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
  
      {/* Modal for editing products */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Product"
        className={styles.ReactModal__Content}
        overlayClassName={styles.ReactModal__Overlay}
      >
        <h2 className={styles.modalHeader}>Editar Producto</h2>
        <div className={styles.modalBody}>
          <form className={styles.formCentered}>
          <label>Nombre del Producto</label>
            <input
              type="text"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              placeholder="Nombre del Producto"
              className={styles.inputField}
            />
            <label>Precio</label>
            <input
              type="number"
              value={productPrice}
              onChange={(e) => setProductPrice(e.target.value)}
              placeholder="Precio del Producto"
              className={styles.inputField}
            />
            <label>Stock</label>
            <input
            
              type="number"
              value={productStock}
              onChange={(e) => setProductStock(e.target.value)}
              placeholder="Stock del Producto"
              className={styles.inputField}
            />
            <label>Categoría</label>
            <select
              value={productCategory}
              onChange={(e) => setProductCategory(e.target.value)}
              className={styles.inputField}
            >
              <option value="" disabled>Seleccionar Categoría</option> {/* Disable placeholder */}

              {categories.map((category) => (
                <option key={category.id} value={category.name}>
                  {category.name}
                </option>
              ))}
              <option value="Agregar Nueva Categoría">Agregar Nueva Categoría</option>
            </select>
            {productCategory === 'Agregar Nueva Categoría' && (
              <input
                type="text"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                placeholder="New Category Name"
                className={styles.inputField}
              />
            )}
            <input
              type="file"
              multiple
              onChange={handleImageUpload}
              ref={fileInputRef}
              className={styles.inputFile}
            />
            <div className={styles.imagePreview}>
              {editProduct?.imageUrls?.map((imageUrl, index) => (
                <div className={styles.imageThumbnail}>
                  <img src={imageUrl} alt={`Product Image ${index}`} />
                  <button
                    className={index === principalImageIndex ? styles.activeImageButton : styles.inactiveImageButton}
                    onClick={(e) => handlePrincipalImageSelect(index, e)}
                    type="button"
                  >
                    {index === principalImageIndex ? 'Principal' : 'Setear'}
                  </button>
                  <button
                    className={styles.deleteButton}
                    onClick={(e) => handleDeleteImage(imageUrl, e)} // Pass `e` to prevent default
                    type="button"
                  >
                    Eliminar
                  </button>
                </div>
              ))}
            </div>
            <label>Descripción Resumida</label>
            <input
              type="text"
              value={productShortDescription}
              onChange={(e) => setProductShortDescription(e.target.value)}
              placeholder="Descripción Resumida (Max 43 caract)"
              className={styles.inputField}
            />
            <label>Descripción Completa</label>
            <textarea
              value={productFullDescription}
              onChange={(e) => setProductFullDescription(e.target.value)}
              placeholder="Descripción Completa"
              className={styles.textAreaField}
            />
            <label className={styles.switchLabel}>
              <input
                type="checkbox"
                checked={hasTarjeta}
                onChange={() => setHasTarjeta(!hasTarjeta)}
                className={styles.switchCheckbox}
              />
              <span className={styles.switchSlider}></span>
              Producto con Tarjeta
            </label>
            <h4>Opciones de Producto (Opcional):</h4>
            {productOptions.map((option, index) => (
              <div key={index} className={styles.optionGroup}>
                <label>Nombre</label>
                <input
                  type="text"
                  value={option.name}
                  onChange={(e) => handleOptionChange(index, 'name', e.target.value)}
                  label
                  placeholder="Nombre de la Opción"
                  className={styles.inputField}
                />
                <label>Precio</label>
                <input
                  type="number"
                  value={option.price}
                  onChange={(e) => handleOptionChange(index, 'price', e.target.value)}
                  placeholder="Price"
                  className={styles.inputField}
                />
              </div>
            ))}
            <div className={styles.buttonRow}>
              <button type="button" className={styles.popupButton} onClick={handleAddOption}>
                Agregar Opciones
              </button>
              <button type="button" className={styles.popupButton} onClick={handleUpdateProduct} disabled={loading}>
                Actualizar Producto
              </button>
              <button onClick={closeModal} className={styles.modalCloseButton}>
                Cerrar
              </button>
            </div>
          </form>
        </div>
      </Modal>
  
      {/* Modal for adding products */}
      <Modal
        isOpen={addProductModalOpen}
        onRequestClose={() => setAddProductModalOpen(false)}
        contentLabel="Add New Product"
        className={styles.ReactModal__Content}
        overlayClassName={styles.ReactModal__Overlay}
      >
        <h2 className={styles.modalHeader}>Agregar Nuevo Producto</h2>
        <div className={styles.modalBody}>
          <form className={styles.formCentered}>
            <input
              type="text"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              placeholder="Nombre del Producto"
              className={styles.inputField}
            />
            <input
              type="number"
              value={productPrice}
              onChange={(e) => setProductPrice(e.target.value)}
              placeholder="Precio del Producto"
              className={styles.inputField}
            />
            <input
              type="number"
              value={productStock}
              onChange={(e) => setProductStock(e.target.value)}
              placeholder="Stock del Producto"
              className={styles.inputField}
            />
            <select
              value={productCategory}
              onChange={(e) => setProductCategory(e.target.value)}
              className={styles.inputField}
            >
              <option value="" disabled>Seleccionar Categoría</option> {/* Disable placeholder */}

              {categories.map((category) => (
                <option key={category.id} value={category.name}>
                  {category.name}
                </option>
              ))}
              <option value="Agregar Nueva Categoría">Agregar Nueva Categoría</option>
            </select>
            {productCategory === 'Agregar Nueva Categoría' && (
              <input
                type="text"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                placeholder="New Category Name"
                className={styles.inputField}
              />
            )}
            <input
              type="file"
              multiple
              onChange={handleImageUpload}
              ref={fileInputRef}
              className={styles.inputFile}
            />
            <div className={styles.imagePreview}>
              {productImages.map((image, index) => (
                <div key={index} className={styles.imageThumbnail}>
                  <img src={URL.createObjectURL(image)} alt={`Product Image ${index}`} />
                  <button
                    className={index === principalImageIndex ? styles.activeImageButton : styles.inactiveImageButton}
                    onClick={(e) => handlePrincipalImageSelect(index, e)}
                  >
                    {index === principalImageIndex ? 'Principal' : 'Set as Principal'}
                  </button>
                </div>
              ))}
            </div>
            <input
              type="text"
              value={productShortDescription}
              onChange={(e) => setProductShortDescription(e.target.value)}
              placeholder="Descripción Resumida (Max 43 caract)"
              className={styles.inputField}
            />
            <textarea
              value={productFullDescription}
              onChange={(e) => setProductFullDescription(e.target.value)}
              placeholder="Descripción Completa"
              className={styles.textAreaField}
            />
            <label className={styles.switchLabel}>
              <input
                type="checkbox"
                checked={hasTarjeta}
                onChange={() => setHasTarjeta(!hasTarjeta)}
                className={styles.switchCheckbox}
              />
              <span className={styles.switchSlider}></span>
              Producto con Tarjeta
            </label>
            <h4>Opciones de Producto (Opcional):</h4>
            {productOptions.map((option, index) => (
              <div key={index} className={styles.optionGroup}>
                <input
                  type="text"
                  value={option.name}
                  onChange={(e) => handleOptionChange(index, 'name', e.target.value)}
                  placeholder="Nombre de la Opción"
                  className={styles.inputField}
                />
                <input
                  type="number"
                  value={option.price}
                  onChange={(e) => handleOptionChange(index, 'price', e.target.value)}
                  placeholder="Precio"
                  className={styles.inputField}
                />
              </div>
            ))}
            <div className={styles.buttonRow}>
              <button type="button" className={styles.popupButton} onClick={handleAddOption}>
                Agregar Opciones
              </button>
              <button type="button" className={styles.popupButton} onClick={handleAddProduct} disabled={loading}>
                Agregar Producto
              </button>
              <button onClick={() => setAddProductModalOpen(false)} className={styles.modalCloseButton}>
                Cerrar
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );


};

export default AdminProducts;
