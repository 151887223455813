import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, getDocs, doc, updateDoc } from 'firebase/firestore';
import { Table, Modal, Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as XLSX from 'xlsx'; // Import xlsx for Excel download
import styles from './AdminOrders.module.css';

const MySwal = withReactContent(Swal);

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showOrderDetailsModal, setShowOrderDetailsModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [newStatus, setNewStatus] = useState('');
  const [newPaymentStatus, setNewPaymentStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const firestore = getFirestore();

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      const q = query(collection(firestore, 'orders'));
      const querySnapshot = await getDocs(q);
      const ordersData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      const sortedOrders = ordersData.sort((a, b) => new Date(b.date) - new Date(a.date));
      setOrders(sortedOrders);
      setLoading(false);
    };

    fetchOrders();
  }, [firestore]);

  const handleDownloadReport = () => {
    const formattedOrders = orders.map((order) => ({
      'Número de Orden': order.orderNumber || 'N/A',
      'Fecha': new Date(order.date).toLocaleString('es-CR'),
      'Nombre Cliente': `${order.contactInfo?.recipientName} ${order.contactInfo?.recipientLastName || ''}`,
      'Teléfono': order.contactInfo?.telefono || 'N/A',
      'Total': order.total,
      'Status Orden': order.status,
      'Status Pago': order.paymentStatus || 'Pago Pendiente',
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedOrders);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Pedidos');

    XLSX.writeFile(workbook, 'Reporte_Pedidos.xlsx');
  };

  const handleSaveOrderStatus = async () => {
    if (!newStatus) {
      MySwal.fire({ title: 'Seleccione un estado válido', icon: 'warning', confirmButtonText: 'OK' });
      return;
    }
    setLoading(true);
    const orderDocRef = doc(firestore, 'orders', selectedOrder.id);
    await updateDoc(orderDocRef, { status: newStatus });
    setOrders((prevOrders) =>
      prevOrders.map((order) => (order.id === selectedOrder.id ? { ...order, status: newStatus } : order))
    );
    setShowStatusModal(false);
    setLoading(false);

    MySwal.fire({ title: 'Estado de la orden actualizado!', icon: 'success', confirmButtonText: 'OK' });
  };

  const handleSavePaymentStatus = async () => {
    if (!newPaymentStatus) {
      MySwal.fire({ title: 'Seleccione un estado de pago válido', icon: 'warning', confirmButtonText: 'OK' });
      return;
    }
    setLoading(true);
    const orderDocRef = doc(firestore, 'orders', selectedOrder.id);
    await updateDoc(orderDocRef, { paymentStatus: newPaymentStatus });
    setOrders((prevOrders) =>
      prevOrders.map((order) => (order.id === selectedOrder.id ? { ...order, paymentStatus: newPaymentStatus } : order))
    );
    setShowPaymentModal(false);
    setLoading(false);

    MySwal.fire({ title: 'Estado de pago actualizado!', icon: 'success', confirmButtonText: 'OK' });
  };

  const handleOpenStatusModal = (order) => {
    setSelectedOrder(order);
    setNewStatus(order.status);
    setShowStatusModal(true);
  };

  const handleOpenPaymentModal = (order) => {
    setSelectedOrder(order);
    setNewPaymentStatus(order.paymentStatus);
    setShowPaymentModal(true);
  };

  const handleOpenOrderDetailsModal = (order) => {
    setSelectedOrder(order);
    setShowOrderDetailsModal(true);
  };

  const handleCloseModals = () => {
    setShowStatusModal(false);
    setShowPaymentModal(false);
    setShowOrderDetailsModal(false);
    setSelectedOrder(null);
    setNewStatus('');
    setNewPaymentStatus('');
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CR', {
      style: 'currency',
      currency: 'CRC',
      minimumFractionDigits: 0,
    }).format(value);
  };

  return (
    <div className={styles.adminOrdersContainer}>
      {loading && <div className={styles.loadingOverlay}></div>}

      <h2 className={styles.adminOrdersTitle}>Manage Orders</h2>
      <Button onClick={handleDownloadReport} className={styles.adminOrdersButton}>
        Descargar Reporte
      </Button>

      <Table striped bordered hover className={styles.adminOrdersTable}>
        <thead>
          <tr>
            <th>Número de Orden</th>
            <th>Fecha</th>
            <th>Nombre Cliente</th>
            <th>Teléfono</th>
            <th>Total</th>
            <th>Status Orden</th>
            <th>Status Pago</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>{order.orderNumber || 'N/A'}</td>
              <td>{new Date(order.date).toLocaleString('es-CR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
              })}</td>
              <td>{order.contactInfo?.recipientName} {order.contactInfo?.recipientLastName || ''}</td>
              <td>{order.contactInfo?.telefono || 'N/A'}</td>
              <td>{formatCurrency(order.total)}</td>
              <td>{order.status}</td>
              <td>{order.paymentStatus || "Pago Pendiente"}</td>
              <td>
                <button className={styles.adminOrdersButton} onClick={() => handleOpenStatusModal(order)}>
                  Actualizar Status
                </button>
                <button className={styles.adminOrdersButton} onClick={() => handleOpenPaymentModal(order)}>
                  Actualizar Status Pago
                </button>
                <button className={styles.adminOrdersButton} onClick={() => handleOpenOrderDetailsModal(order)}>
                  Ver Orden
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Order Status */}
      <Modal show={showStatusModal} onHide={handleCloseModals}>
        <Modal.Header closeButton className={styles.modalHeader}>
          <Modal.Title>Actualizar Orden Status</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Form>
            <Form.Group controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
              >
  <option disabled value="">Seleccionar Status</option>
  <option value="Pendiente">Pendiente</option>
  <option value="Procesando">Procesando</option>
  <option value="En Espera de Pago">En Espera de Pago</option>
  <option value="Listo para Recolección">Listo para Recolección</option>
  <option value="En camino">En camino</option>
  <option value="Entregado">Entregado</option>
  <option value="Pedido Confirmado">Pedido Confirmado</option>
  <option value="Cancelado">Cancelado</option>
  <option value="Cancelado por el Vendedor">Cancelado por el Vendedor</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button onClick={handleSaveOrderStatus} className={styles.modalSaveButton}>Guardar</Button>
          <Button onClick={handleCloseModals} className={styles.modalCloseButton}>Cerrar</Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Payment Status */}
      <Modal show={showPaymentModal} onHide={handleCloseModals}>
        <Modal.Header closeButton className={styles.modalHeader}>
          <Modal.Title>Actualizar Status Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Form>
            <Form.Group controlId="paymentStatus">
              <Form.Label>Status Pago</Form.Label>
              <Form.Control
                as="select"
                value={newPaymentStatus}
                onChange={(e) => setNewPaymentStatus(e.target.value)}
              >
                <option disabled value="">Seleccionar Status Pago</option>
                <option value="Pago Pendiente">Pago Pendiente</option>
                <option value="Pagado">Pagado</option>
                <option value="Pagado en el Local">Pagado en el Local</option>
                <option value="Cancelado">Cancelado</option>
        
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button onClick={handleSavePaymentStatus} className={styles.modalSaveButton}>Guardar</Button>
          <Button onClick={handleCloseModals} className={styles.modalCloseButton}>Close</Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Viewing Order Details */}
      <Modal show={showOrderDetailsModal} onHide={handleCloseModals} size="lg">
        <Modal.Header closeButton className={styles.modalHeader}>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          {selectedOrder && (
            <div>
              <h4>Customer Information</h4>
              <p><strong>Name:</strong> {selectedOrder.contactInfo?.recipientName} {selectedOrder.contactInfo?.recipientLastName || ''}</p>
              <p><strong>Phone:</strong> {selectedOrder.contactInfo?.telefono || 'N/A'}</p>
              <p><strong>Email:</strong> {selectedOrder.email}</p>

              <h4>Shipping Information</h4>
              {selectedOrder.shippingMethod === 'delivery' ? (
                <div>
                  <p><strong>Address:</strong> {selectedOrder.shippingInfo?.direccion || 'N/A'}</p>
                  <p><strong>Province:</strong> {selectedOrder.shippingInfo?.provincia || 'N/A'}</p>
                  <p><strong>Canton:</strong> {selectedOrder.shippingInfo?.canton || 'N/A'}</p>
                  <p><strong>District:</strong> {selectedOrder.shippingInfo?.distrito || 'N/A'}</p>
                  <p><strong>Postal Code:</strong> {selectedOrder.shippingInfo?.codigoPostal || 'N/A'}</p>
                  <p><strong>Delivery Date:</strong> {selectedOrder.deliveryDate || 'N/A'}</p>
                  <p><strong>Delivery Time:</strong> {selectedOrder.deliveryRange || 'N/A'}</p>
                  <p><strong>Additional Comments:</strong> {selectedOrder.additionalComments || 'N/A'}</p>
                </div>
              ) : (
                <p>Pickup at store</p>
              )}

              <h4>Payment Information</h4>
              <p><strong>Payment Method:</strong> {selectedOrder.paymentMethod || 'N/A'}</p>

              <h4>Order Summary</h4>
              <ul>
                {selectedOrder.items.map((item, index) => (
                  <li key={index}>
                    {item.name} - {formatCurrency(item.price)} x {item.quantity}
                    {item.option && <span> (Option: {item.option.name})</span>}
                    {item.tarjetaMessage && <p><strong>Message on Card:</strong> {item.tarjetaMessage}</p>}
                  </li>
                ))}
              </ul>
              <p><strong>Delivery:</strong> {formatCurrency(selectedOrder.deliveryPrice || 0)}</p>
              <p><strong>Total:</strong> {formatCurrency(selectedOrder.total)}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button onClick={handleCloseModals} className={styles.modalCloseButton}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminOrders;
