// src/components/ProductReview.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { addReview, getReviews } from '../services/firestoreService';
import ReactStars from 'react-rating-stars-component';
import { auth, firestore } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';

const ProductReview = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState({ rating: 0, text: '' });
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsList = await getReviews(productId);
        setReviews(reviewsList);
      } catch (err) {
        Swal.fire('Error', 'Failed to fetch reviews. Please try again later.', 'error');
        console.error('Failed to fetch reviews:', err);
      }
    };

    fetchReviews();

    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, [productId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReview((prevReview) => ({ ...prevReview, [name]: value }));
  };

  const handleRatingChange = (newRating) => {
    setNewReview((prevReview) => ({ ...prevReview, rating: newRating }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      Swal.fire({
        title: 'Inicia sesión',
        text: 'Debes iniciar sesión o crear una cuenta para agregar una reseña.',
        icon: 'warning',
        confirmButtonText: 'Ir a Login',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/customer/login');
        }
      });
      return;
    }

    if (newReview.rating === 0) {
      Swal.fire('Error', 'Por favor selecciona una calificación antes de enviar tu reseña.', 'error');
      return;
    }

    try {
      const userDoc = await getDoc(doc(firestore, 'users', user.uid));
      const userName = userDoc.exists() ? userDoc.data().name : user.email;

      const reviewToSubmit = {
        ...newReview,
        userName,
      };

      await addReview(productId, reviewToSubmit);
      setReviews((prevReviews) => [...prevReviews, reviewToSubmit]);
      setNewReview({ rating: 0, text: '' });

      Swal.fire('Éxito', 'Tu reseña fue enviada correctamente.', 'success');
    } catch (err) {
      Swal.fire('Error', 'No se pudo agregar la reseña. Por favor, inténtalo más tarde.', 'error');
      console.error('No se pudo agregar la reseña:', err);
    }
  };

  return (
    <div className="container">
      <h2>Reseñas de Productos</h2>
      <ul className="list-group mb-4">
        {reviews.map((review, index) => (
          <li key={index} className="list-group-item">
            <strong>Calificación: </strong>
            <ReactStars
              count={5}
              value={review.rating}
              edit={false}
              size={24}
              activeColor="#ffd700"
            />
            <br />
            <strong>Reseña: </strong>{review.text}
            <br />
            <strong>De: </strong>{review.userName}
          </li>
        ))}
      </ul>
      <h3>Agregar Reseña</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Calificación</label>
          <ReactStars
            count={5}
            onChange={handleRatingChange}
            size={24}
            activeColor="#ffd700"
            value={newReview.rating}
          />
        </div>
        <div className="form-group">
          <label>Reseña</label>
          <textarea
            name="text"
            value={newReview.text}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <button type="submit" className="btn btn-primary mt-2">Enviar Reseña</button>
      </form>
    </div>
  );
};

export default ProductReview;