import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.about}>
          <h4>Sobre Armony Floristería</h4>
          <p>Armony Floristería es tu floristería en línea de confianza. Ofrecemos hermosos arreglos para cada ocasión, elaborados con amor y dedicación.</p>
          <Link to="/about">Conoce más sobre nosotros</Link>
        </div>
        <div className={styles.customerService}>
          <h4>Atención al Cliente</h4>
          <ul>
            <li><Link to="/return-cancellation-policy">Política de Devolución y Cancelación</Link></li>
            <li><Link to="/terms-conditions">Términos y Condiciones</Link></li>
            <li><Link to="/shipping-information">Información de Envío</Link></li>
            <li><Link to="/privacy-policy">Política de Privacidad</Link></li>
          </ul>
        </div>
        <div className={styles.contact}>
          <h4>Contáctanos</h4>
          <p>Email: <a href="mailto:support@armonyfloristeria.com">info@armonyfloristeria.com</a></p>
          <p>WhatsApp: <a href="https://wa.me/+50664037952" target="_blank" rel="noopener noreferrer">+506 6403 7952</a><br /></p>
          <p>Teléfono: +506 2203 0537</p>
          <p>Dirección: San José, Costa Rica</p>
          <p><strong>Horario:</strong> L a D 9:00 am - 4:00 pm</p>
        </div>
        <div className={styles.social}>
          <h4>Síguenos</h4>
          <a href="https://www.facebook.com/armonycr/" target="_blank" rel="noopener noreferrer">Facebook</a><br />
          <a href="https://www.instagram.com/florist_armony/" target="_blank" rel="noopener noreferrer">Instagram</a><br />
          <a href="https://www.tiktok.com/@florist_armony_c.r" target="_blank" rel="noopener noreferrer">Tiktok</a>
        </div>
      </div>
      <div className={styles.paymentInfo}>
        <p><strong>Pago Seguro:</strong> Aceptamos todas las formas de pago.</p>
      </div>
      <div className={styles.copyright}>
        <p>&copy; {new Date().getFullYear()} Armony Floristería. Todos los derechos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;
