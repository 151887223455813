import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import {
  loadCart,
  saveCart,
  updateProductStock,
  saveOrder,
  getOrderCount,
  updateOrderCount,
} from '../services/firestoreService';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import Modal from 'react-modal';
import styles from './Checkout.module.css';
import provinces from '../data/provinces.json'; // Import provinces from JSON
import cantonPrices from '../data/cantonPrices.json'; // Import canton prices from JSON
import Swal from 'sweetalert2';

const Checkout = ({ toggleCartSidebar }) => {
  const { cart, setCart } = useCart();
  const [recipientEmail, setRecipientEmail] = useState(auth.currentUser ? auth.currentUser.email : '');
  const [shippingMethod, setShippingMethod] = useState('delivery');
  const [contactInfo, setContactInfo] = useState({
    recipientName: '',
    recipientLastName: '',
    telefono: '',
  });
  const [shippingInfo, setShippingInfo] = useState({
    direccion: '',
    provincia: 'San José',
    canton: '',
    distrito: '',
    codigoPostal: '',
    pais: 'Costa Rica',
  });
  const [deliveryPrice, setDeliveryPrice] = useState(0); // Initial delivery price
  const [saveAccountInfo, setSaveAccountInfo] = useState(false);
  const [saveDeliveryInfo, setSaveDeliveryInfo] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [isPaymentProcessed, setIsPaymentProcessed] = useState(false);
  const [orderID, setOrderID] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false); // New state for disabling button
  const [deliveryDate, setDeliveryDate] = useState(''); // For date input
  const [deliveryRange, setDeliveryRange] = useState(''); // For delivery range input
  const [additionalComments, setAdditionalComments] = useState(''); // Optional comments
  const navigate = useNavigate();

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const namePattern = /^[a-zA-Z\s]+$/;
  const phonePattern = /^[0-9]{8}$/; // Costa Rican phone number (8 digits)
  const postalCodePattern = /^[0-9]+$/;

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(firestore, 'users', auth.currentUser.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setContactInfo({
            recipientName: data.name || '',
            recipientLastName: data.lastName || '',
            telefono: data.telefono || '',
          });
          setShippingInfo({
            direccion: data.direccion || '',
            provincia: data.provincia || 'San José',
            canton: data.canton || '',
            distrito: data.distrito || '',
            codigoPostal: data.codigoPostal || '',
            pais: 'Costa Rica',
          });
          setRecipientEmail(auth.currentUser.email);
        }
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (shippingMethod === 'pickup') {
      setDeliveryPrice(0);
    } else {
      const price = cantonPrices[shippingInfo.canton] || 0;
      setDeliveryPrice(price);
    }
  }, [shippingInfo.canton, shippingMethod]);

  const handleContactInputChange = (e) => {
    const { name, value } = e.target;
    setContactInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleShippingInputChange = (e) => {
    const { name, value } = e.target;
    setShippingInfo((prevInfo) => ({ ...prevInfo, [name]: value }));

    if (name === 'provincia') {
      setShippingInfo((prevInfo) => ({ ...prevInfo, canton: '', distrito: '' }));
    }
  };

  const handleEmailChange = (e) => {
    setRecipientEmail(e.target.value);
  };

  const validateFields = () => {
    const today = new Date().toISOString().split('T')[0];

    if (!deliveryRange) {
      Swal.fire('Error', 'Por favor, selecciona un rango de hora para la entrega.', 'error');
      return false;
    }

    if (!emailPattern.test(recipientEmail)) {
      Swal.fire('Error', 'Por favor, ingresa un correo electrónico válido.', 'error');
      return false;
    }

    if (!namePattern.test(contactInfo.recipientName)) {
      Swal.fire('Error', 'Por favor, ingresa un nombre válido.', 'error');
      return false;
    }

    if (!namePattern.test(contactInfo.recipientLastName)) {
      Swal.fire('Error', 'Por favor, ingresa apellidos válidos.', 'error');
      return false;
    }

    if (!phonePattern.test(contactInfo.telefono)) {
      Swal.fire('Error', 'Por favor, ingresa un número de teléfono válido (8 dígitos).', 'error');
      return false;
    }

    if (shippingMethod === 'delivery' && !shippingInfo.direccion) {
      Swal.fire('Error', 'Por favor, ingresa una dirección válida.', 'error');
      return false;
    }

    if (!deliveryDate || deliveryDate < today) {
      Swal.fire('Error', 'Por favor, selecciona una fecha de entrega válida.', 'error');
      return false;
    }

    if (!paymentMethod) {
      Swal.fire('Error', 'Por favor, selecciona un método de pago.', 'error');
      return false;
    }

    return true;
  };

  const handleCheckout = async () => {
    if (!validateFields()) return;

    try {
      setIsProcessing(true);

      let isStockAvailable = true;
      const updatedCart = [];

      for (const item of cart) {
        const newStock = await updateProductStock(item.id, item.quantity);
        if (newStock < 0) {
          isStockAvailable = false;
          updatedCart.push({ ...item, outOfStock: true });
        } else {
          updatedCart.push({ ...item, outOfStock: false });
        }
      }

      if (isStockAvailable) {
        const currentOrderCount = await getOrderCount();
        const newOrderNumber = currentOrderCount + 1;

        const order = {
          userId: auth.currentUser ? auth.currentUser.uid : 'guest',
          email: recipientEmail,
          items: updatedCart,
          total:
            updatedCart.reduce(
              (acc, item) => (item.outOfStock ? acc : acc + item.price * item.quantity),
              0
            ) + deliveryPrice,
          shippingMethod,
          contactInfo,
          shippingInfo: shippingMethod === 'delivery' ? shippingInfo : null,
          deliveryPrice,
          paymentMethod,
          date: new Date().toISOString(),
          orderNumber: newOrderNumber,
          deliveryDate,
          deliveryRange,
          additionalComments,
        };

        await saveOrder(order);
        await updateOrderCount(newOrderNumber);

      // Update user's ordersPlaced and totalSpent
      if (auth.currentUser) {
        const userDocRef = doc(firestore, 'users', auth.currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();

        await updateDoc(userDocRef, {
          ordersPlaced: (userData.ordersPlaced || 0) + 1, // Increment orders count
          totalSpent: (userData.totalSpent || 0) + order.total, // Add order total to totalSpent
        });
      }


        if (auth.currentUser && saveAccountInfo) {
          const userDocRef = doc(firestore, 'users', auth.currentUser.uid);
          await updateDoc(userDocRef, {
            name: contactInfo.recipientName,
            lastName: contactInfo.recipientLastName,
            telefono: contactInfo.telefono,
          });
        }

        if (auth.currentUser && saveDeliveryInfo) {
          const userDocRef = doc(firestore, 'users', auth.currentUser.uid);
          await updateDoc(userDocRef, {
            direccion: shippingInfo.direccion,
            provincia: shippingInfo.provincia,
            canton: shippingInfo.canton,
            distrito: shippingInfo.distrito,
            codigoPostal: shippingInfo.codigoPostal,
          });
        }

        setCart([]);
        localStorage.removeItem('cart');
        if (auth.currentUser) {
          await saveCart([]);
        }

        setOrderID(newOrderNumber);
        setIsPaymentProcessed(true);
      } else {
        Swal.fire('Error', 'Algunos artículos están agotados. Revisa tu carrito.', 'error');
      }
    } catch (err) {
      console.error('Failed to update stock:', err);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCloseModal = async () => {
    setIsPaymentProcessed(false);
    navigate('/order-history');
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('es-CR', {
      style: 'currency',
      currency: 'CRC',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  return (
    <div className={styles.checkoutContainer}>
      <h2 className={styles.title}>Checkout</h2>

      <div className={`${styles.formGroup} ${styles.contactInfo}`}>
        <h3 className={styles.sectionTitle}>Información de Contacto</h3>

        <div className={styles.inputContainer}>
          <i className={`fa fa-envelope ${styles.icon}`}></i>
          <input
            type="email"
            value={recipientEmail}
            onChange={handleEmailChange}
            placeholder="Email"
            required
            className={styles.formControl}
          />
        </div>

        <div className={styles.inputContainer}>
          <i className={`fa fa-user ${styles.icon}`}></i>
          <input
            type="text"
            name="recipientName"
            value={contactInfo.recipientName}
            onChange={handleContactInputChange}
            placeholder="Nombre"
            required
            className={styles.formControl}
          />
        </div>
        <div className={styles.inputContainer}>
          <i className={`fa fa-user ${styles.icon}`}></i>
          <input
            type="text"
            name="recipientLastName"
            value={contactInfo.recipientLastName}
            onChange={handleContactInputChange}
            placeholder="Apellidos"
            required
            className={styles.formControl}
          />
        </div>
        <div className={styles.inputContainer}>
          <i className={`fa fa-phone ${styles.icon}`}></i>
          <input
            type="text"
            name="telefono"
            value={contactInfo.telefono}
            onChange={handleContactInputChange}
            placeholder="Teléfono"
            required
            className={styles.formControl}
          />
        </div>
      </div>

      {auth.currentUser && (
        <div className={styles.checkboxGroup}>
          <input
            type="checkbox"
            className="form-check-input"
            id="saveAccountInfo"
            checked={saveAccountInfo}
            onChange={(e) => setSaveAccountInfo(e.target.checked)}
          />
          <label htmlFor="saveAccountInfo">Guardar información de contacto para futuras compras</label>
        </div>
      )}

      <div className={`${styles.formGroup} ${styles.deliverySection}`}>
        <h4 className={styles.sectionTitle}>Día de Entrega</h4>
        <input
          type="date"
          name="deliveryDate"
          value={deliveryDate}
          onChange={(e) => setDeliveryDate(e.target.value)}
          min={new Date().toISOString().split('T')[0]}
          className={`${styles.formControl} ${styles.shortInput}`}
          required
        />
      </div>

      <div className={`${styles.formGroup} ${styles.deliverySection}`}>
        <h4 className={styles.sectionTitle}>Hora de Entrega</h4>
        <select
          name="deliveryRange"
          value={deliveryRange}
          onChange={(e) => setDeliveryRange(e.target.value)}
          className={styles.formControl}
          required
        >
          <option value="" disabled>
            Selecciona un rango
          </option>
          <option value="6 a 9 AM">6 a 9 AM</option>
          <option value="9 a 11 AM">9 a 11 AM</option>
          <option value="11 AM a 1 PM">11 AM a 1 PM</option>
          <option value="1 a 3 PM">1 a 3 PM</option>
          <option value="3 a 6 PM">3 a 6 PM</option>
          <option value="6 a 9 PM">6 a 9 PM</option>
          <option value="9 a 11 PM">9 a 11 PM</option>
        </select>
      </div>

      <div className={styles.formGroup}>
        <h4 className={styles.sectionTitle}>Comentarios Adicionales (Opcional)</h4>
        <textarea
          name="additionalComments"
          value={additionalComments}
          onChange={(e) => setAdditionalComments(e.target.value)}
          placeholder="Especifica instrucciones especiales para tu pedido o entrega"
          className={styles.formControl}
        />
      </div>

      <div className={`${styles.formGroup} ${styles.shippingSection}`}>
        <h3 className={styles.sectionTitle}>Método de Entrega</h3>
        <div className={styles.radioGroup}>
          <label>
            <input
              type="radio"
              name="shippingMethod"
              value="pickup"
              checked={shippingMethod === 'pickup'}
              onChange={(e) => setShippingMethod(e.target.value)}
            />
            Recoger en el Local
          </label>
          <label>
            <input
              type="radio"
              name="shippingMethod"
              value="delivery"
              checked={shippingMethod === 'delivery'}
              onChange={(e) => setShippingMethod(e.target.value)}
            />
            Envío
          </label>
        </div>
      </div>

      {shippingMethod === 'pickup' && (
        <div className={styles.pickupInfo}>
          <p>
            <strong>Estamos en:</strong> Plaza Vistana Oeste
          </p>
          <p>
            <strong>Dirección:</strong> Lindora, entre a TacoBell y Soda Tapia San José Pozos
          </p>
        </div>
      )}

      {shippingMethod === 'delivery' && (
        <div className={styles.formGroup}>
          <h4 className={styles.sectionTitle}>Información de Entrega</h4>
          <input
            type="text"
            name="direccion"
            value={shippingInfo.direccion}
            onChange={handleShippingInputChange}
            placeholder="Dirección Completa"
            required
            className={styles.formControl}
          />
          <div className={styles.addressFields}>
            <label>Provincia</label>
            <select
              name="provincia"
              value={shippingInfo.provincia}
              onChange={handleShippingInputChange}
              className={styles.formControl}
            >
              {Object.keys(provinces).map((prov) => (
                <option key={prov} value={prov}>
                  {prov}
                </option>
              ))}
            </select>

            <label>Cantón</label>
            <select
              name="canton"
              value={shippingInfo.canton}
              onChange={handleShippingInputChange}
              className={styles.formControl}
            >
              {shippingInfo.provincia &&
                Object.keys(provinces[shippingInfo.provincia]?.cantones || {}).map((canton) => (
                  <option key={canton} value={canton}>
                    {canton}
                  </option>
                ))}
            </select>

            <label>Distrito</label>
            <select
              name="distrito"
              value={shippingInfo.distrito}
              onChange={handleShippingInputChange}
              className={styles.formControl}
            >
              {shippingInfo.canton &&
                provinces[shippingInfo.provincia]?.cantones[shippingInfo.canton]?.map((distrito) => (
                  <option key={distrito} value={distrito}>
                    {distrito}
                  </option>
                ))}
            </select>

            <label>Código Postal (Opcional)</label>
            <input
              type="text"
              name="codigoPostal"
              value={shippingInfo.codigoPostal}
              onChange={handleShippingInputChange}
              placeholder="Código Postal"
              className={styles.formControl}
              pattern={postalCodePattern.source}
            />
          </div>

          {auth.currentUser && (
            <div className={styles.checkboxGroup}>
              <input
                type="checkbox"
                className="form-check-input"
                id="saveDeliveryInfo"
                checked={saveDeliveryInfo}
                onChange={(e) => setSaveDeliveryInfo(e.target.checked)}
              />
              <label htmlFor="saveDeliveryInfo">
                Guardar la información de entrega para futuras compras
              </label>
            </div>
          )}
        </div>
      )}

      <div className={`${styles.formGroup} ${styles.paymentSection}`}>
        <h3 className={styles.sectionTitle}>Método de Pago</h3>
        <div className={styles.radioGroup}>
          <label>
            <input
              type="radio"
              name="paymentMethod"
              value="SINPE Movil"
              checked={paymentMethod === 'SINPE Movil'}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            SINPE Movil
          </label>
          <label>
            <input
              type="radio"
              name="paymentMethod"
              value="Transferencia Bancaria"
              checked={paymentMethod === 'Transferencia Bancaria'}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            Transferencia Bancaria
          </label>
          <label>
            <input
              type="radio"
              name="paymentMethod"
              value="Pagar en el Local"
              checked={paymentMethod === 'Pagar en el Local'}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            Pagar en el Local
          </label>
          <label>
            <input
              type="radio"
              name="paymentMethod"
              value="PayPal"
              checked={paymentMethod === 'PayPal'}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            PayPal
          </label>
        </div>

        {paymentMethod === 'SINPE Movil' && (
          <div className={styles.paymentInstructions}>
            <p>
              <strong>Por favor hacer el pago al SINPE 64037952 a nombre de Armony Floristería.</strong>
            </p>
            <p>En el detalle, por favor incluir el número de orden y tu nombre.</p>
          </div>
        )}

        {paymentMethod === 'Transferencia Bancaria' && (
          <div className={styles.paymentInstructions}>
            <p>
              <strong>Por favor hacer el pago en una de las siguientes cuentas:</strong>
            </p>
            <ul>
              <li>BAC IBAN: CR610102000094400831775</li>
              <li>BN IBAN: CR6101423400009440083179</li>
              <li>BCR IBAN: CR610142340000944008317</li>
            </ul>
            <p>En el detalle, por favor incluir el número de orden y tu nombre.</p>
          </div>
        )}

{paymentMethod === 'Pagar en el Local' && (
  <div className={styles.paymentInstructions}>
    <p>
      <strong>Nota Importante:</strong>
    </p>
    <p>
      Para iniciar la preparación de tu pedido, es necesario realizar un 
      <strong> depósito del 50% del total</strong> y enviarnos el comprobante al{' '}
      <strong>
        <a href="https://wa.me/+50664037952" target="_blank" rel="noopener noreferrer">
          WhatsApp: 6403 7952
        </a>
      </strong>.
    </p>
    <p>
      Una vez recibido el depósito, comenzaremos a preparar tu pedido y te notificaremos 
      cuando esté listo para recoger. El saldo restante puede pagarse al recoger el pedido 
      en el local mediante tarjeta de crédito, débito, efectivo, SINPE o transferencia bancaria.
    </p>
    <p>
      Si prefieres pagar el total en el local, ten en cuenta que tu pedido será preparado después 
      del pago completo, y podrías esperar el tiempo de preparación en nuestra tienda.
    </p>
  </div>
)}



        {paymentMethod === 'PayPal' && (
          <div className={styles.paymentInstructions}>
            <p>
              <strong>
                Por favor envía el pago a la cuenta de PayPal: info@armonyfloristeria.com.
              </strong>
            </p>
            <p>En el detalle, incluye tu número de orden y nombre.</p>
          </div>
        )}
      </div>

      <div className={`${styles.formGroup} ${styles.orderSummarySection}`}>
        <h3 className={styles.sectionTitle}>Resumen de tu Orden</h3>
        <ul className={styles.orderSummaryList}>
          {cart.map((item, index) => (
            <li key={index} className={styles.orderItem}>
              {item.name} - {formatCurrency(item.price)} x {item.quantity}
            </li>
          ))}
        </ul>
        {shippingMethod === 'delivery' && (
          <h4 className={styles.totalTitle}>Envío: {formatCurrency(deliveryPrice)}</h4>
        )}
        <h4 className={styles.totalTitle}>
          Total: {formatCurrency(cart.reduce((acc, item) => acc + item.price * item.quantity, 0) + deliveryPrice)}
        </h4>
      </div>

      <button onClick={handleCheckout} className={styles.checkoutBtn} disabled={isProcessing}>
        {isProcessing ? (
          <>
            <span className={styles.spinnerSmall}></span> Procesando...
          </>
        ) : (
          'Proceder al Pago'
        )}
      </button>

      {isProcessing && (
        <div className={styles.loadingOverlay}>
          <div className={styles.spinner}></div>
        </div>
      )}

      {isPaymentProcessed && (
        <Modal
          isOpen={isPaymentProcessed}
          onRequestClose={handleCloseModal}
          contentLabel="Confirmación de Orden"
          className={styles.modalContent}
          overlayClassName={styles.modalOverlay}
        >
          <h2>Pedido Realizado con Éxito!</h2>
          {orderID && (
            <>
              <p>Este es tu número de Orden:</p>
              <p>
                <strong>{orderID}</strong>
              </p>
            </>
          )}
          <p>Recuerda por favor incluirlo en el detalle del pago junto a tu nombre.</p>
          <button onClick={handleCloseModal} className="btn btn-primary">
            Cerrar
          </button>
        </Modal>
      )}
    </div>
  );
};

export default Checkout;
